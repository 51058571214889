import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'


Vue.use(Router)

export default new Router({
	routes: [
		{
			path: '/',
			name: 'HomeView',
			component: Home
		},
		{
			path: '/login',
			name: 'LoginView',
			component: () => import('./views/Login.vue')
		},
		{
			path: '/login/:token/:email',
			component: () => import('./views/LoginRecupera.vue')
		},
		{
			path: '/main/',
			component: () => import('./views/Main.vue'),
			children:[
				{path: '', name: 'MainView', component: () => import('./views/Agendamento.vue')},
				{path: 'agendamento',	component: () => import('./views/Agendamento.vue')},
				{path: 'sala',			component: () => import('./views/Sala.vue')},
				{path: 'usuario',		component: () => import('./views/Usuario.vue')},
				{path: 'cupomdesc',		component: () => import('./views/CupomDesconto.vue')},
				{path: 'config',		component: () => import('./views/Configuracao.vue')},
				{path: 'profile',		component: () => import('./views/Profile.vue')},
				{path: 'adminagendamento',		component: () => import('./views/AdminAgendamento.vue')},
			]
		},
		{	path: '/tablet/:sala',
			name: 'TabletView',
			component: () => import('./views/Tablet.vue')
		},
		{
			path: '/*',
			component: Home
		}
	]
})
