import Vue from 'vue'
import BootstrapVue from "bootstrap-vue"

import axios from 'axios'
import VueAxios from 'vue-axios'
import * as Cookies from 'tiny-cookie'
import money from 'v-money'
import VueTheMask from 'vue-the-mask'

import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2';
 
import VueI18n from 'vue-i18n'
import elui from 'element-ui';
import enLang from 'element-ui/lib/locale/lang/pt-br'
import locale from 'element-ui/lib/locale'

import 'element-ui/lib/theme-chalk/index.css';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false

locale.use(enLang)

Vue.use(VueI18n)
Vue.use(BootstrapVue)

if(process.env.NODE_ENV === 'production'){
	axios.defaults.baseURL = './iconsapi/index.php/';
}else{
	axios.defaults.baseURL = 'http://localhost/iconsapi/index.php/';
}
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.crossDomain = true;


Vue.use(VueAxios, axios)
Vue.use(elui)

const options = {
	confirmButtonColor: '#002337',
	cancelButtonColor: '#770a0a',
};
Vue.use(VueSweetalert2, options)

// register directive v-money and component <money>
Vue.use(money,
	{decimal: ',',
	thousands: '.',
	prefix: 'R$ ',
	suffix: '',
	precision: 2,
	masked: false})

moment.defineLocale("pt-br", {})
Vue.prototype.moment = moment


Vue.use(VueTheMask)

/**
 * Number.prototype.format(n, x, s, c)
 * 
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
Number.prototype.format = function(n, x, s, c){
	var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
		num = this.toFixed(Math.max(0, ~~n));

	return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

Number.prototype.round = function(places){
	return +(Math.round(this + "e+" + places)  + "e-" + places);
};

Vue.prototype.$cookie = Cookies;

Vue.directive('scroll', {
	inserted: function (el, binding) {
	  let f = function (evt) {
		if (binding.value(evt, el)) {
		  el.removeEventListener('scroll', f)
		}
	  }
	  el.addEventListener('scroll', f)
	}
  })

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <a>.';
	Vue.config.warnHandler = function (msg, vm, trace) {
	// `trace` is the component hierarchy trace
	if(msg === ignoreWarnMessage){
		msg = null;
		vm = null;
		trace = null;
	}
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
