<template>

	<div id="home_container">
		<div class="flutuante">
			<div class="menu">
				<a href="#servicos" @click="openMenu">Serviços</a>
				<a href="#localizacao" @click="openMenu">Localização</a>
				<a href="https://wa.me/55051991216108" target="_blank">CONTATO</a>
			</div>
			<!--
			<div class="login_menu">
				<router-link class="btn-default light" to="/login">ACESSE SUA CONTA</router-link>
			</div>
		-->
		</div>

		<div class="header">
			<div class="container menudesktop">
				<b-row>
					<b-col cols="12" md="2" class="div_logo_header">
						<a class="logo-header" href="/">
							<img src="@/assets/img/logo-header.png">
						</a>
					</b-col>
					<b-col cols="12" md="7">
						<nav class="mt-2">
							<div class="menu-menu-topo-container">
								<ul id="menu-menu-topo" class="menu">
									<li>
										<a href="#servicos">Serviços</a>
									</li>
									<li>
										<a href="#localizacao">Localização</a>
									</li>
									<li>
										<a href="https://wa.me/55051991216108" target="_blank">CONTATO</a>
									</li>
								</ul>
							</div>
						</nav>
					</b-col>
					<!--
					<b-col cols="12" md="3">
						<div class="text-right login_menu">
							<router-link class="btn-default light" to="/login" style="font-size: 0.8em;">ACESSE SUA CONTA</router-link>
						</div>
					</b-col>
					-->
				</b-row>
			</div>
			<div class="container menumobile">
				<b-row>
					<b-col cols="5" md="2" class="div_logo_header">
						<a class="logo-header" href="/">
							<img src="@/assets/img/logo-header.png">
						</a>
					</b-col>
					<b-col cols="7" md="10" class="text-right text-white mt-2">
						<span @click="openMenu">
							<v-icon class="pointer" name="bars" scale="2" v-show="menuopen"/>
							<v-icon class="pointer" name="times" scale="2" v-show="!menuopen"/>
						</span>
					</b-col>
				</b-row>
			</div>
		</div>

		<!--
		<div class="promocao">
			<img src="@/assets/img/promocao_aniversario.png">
		</div>
		-->

		<div class='slider'>
			<div class='slide1' :class="{imgshow: (carouselEl == 0)}"></div>
			<div class='slide2' :class="{imgshow: (carouselEl == 1)}"></div>
			<div class='slide3' :class="{imgshow: (carouselEl == 2)}"></div>
		</div>

		<div class="div_texto_aux">
			<div class="texto_auxiliar" v-if="carouselEl == 0">NÃO PRECISA PAGAR CONDOMÍNIO</div>
			<div class="texto_auxiliar" v-if="carouselEl == 1">NÃO PRECISA MOBILIAR</div>
			<div class="texto_auxiliar" v-if="carouselEl == 2">PRONTO PARA VOCÊ</div>
		</div>

		<div class="dot_aux">
			<div class="dots" :class="{marked: (carouselEl == 2)}" @click="carouselEl = 2"></div>
			<div class="dots" :class="{marked: (carouselEl == 1)}" @click="carouselEl = 1"></div>
			<div class="dots" :class="{marked: (carouselEl == 0)}" @click="carouselEl = 0"></div>
		</div>

		<div class="content1 bkc_white">
			<b-row class="w-100">
				<div class="col-lg-2 col-md-1"></div>
				<div class="text-right col-lg-4 col-md-5 col-sm-12">
					<div class="text-right titulo_2">
						POR QUE ALUGAR UM CONSULTÓRIO POR MAIS TEMPO DO QUE VOCÊ PRECISA?
					</div>
				</div>
				<div class="texto_2 col-lg-4 col-md-5 col-sm-12">
					A iCONS é a escolha perfeita para profissionais de saúde que procuram um excelente local para trabalhar sem precisar investir na compra de um consultório ou desperdiçar dinheiro com aluguel, condomínio e outras despesas fixas mensais. Na iCONS, você só paga pelas horas e pelos serviços que irá utilizar. E você não precisa se comprometer por períodos maiores do que um mês.
				</div>
				<div class="col-lg-2 col-md-1"></div>
			</b-row>
		</div>

		<div class="parallax_bg1" :style="{backgroundImage: `url(${require(`../assets/img/bg-como-funciona.jpg`)})`}">
			<br>
		</div>

		<div id="servicos" style="position:relative;"></div>

		<div class="content2">
			<h2 class="section-title text-center">SERVIÇOS</h2>
			<ul class="list-services">
				<li>Recepção</li>
				<li>Internet rápida</li>
				<li>Diversos tamanhos de consultórios</li>
				<li>Sala para coffee break</li>
				<li>Sala de procedimentos</li>
				<li>Estacionamento rotativo</li>
				<li>Depósito para guardar equipamentos</li>
				<li>Em breve novos serviços inovadores!</li>
			</ul>
		</div>

		<div class="parallax_bg2" :style="{backgroundImage: `url(${require(`../assets/img/bg-servicos.jpg`)})`}">
			<br>
		</div>

		<div id="localizacao" style="position:relative;"></div>

		<div class="bkc_white localizacao">
			<h2 class="section-title text-center pt-5">Localização</h2>
			<b-row class="w-100">
				<div class="col-lg-2 col-md-1"></div>
				<div class="text-right col-lg-4 col-md-5 col-sm-12 div_title">
					<h3 class="section-title">Seu consultório em um hub de saúde sofisticado e com localização estratégica.</h3>
				</div>
				<div class="texto_2 col-lg-4 col-md-5 col-sm-12">
					<p class="section-subtitle">
						A iCONS está localizada no MedPlex Santana, complexo de saúde no coração do eixo hospitalar de Porto Alegre, que possui leitos de recuperação, leitos de pernoite, salas de cirurgia, suítes de internação, salas de reunião, auditório, espaço fitness, e outros serviços médicos e laboratoriais. Além de atender as suas necessidades profissionais, facilita a interação e qualifica o seu networking.
					</p>
					<p>
						<a class="localization-link" href="https://goo.gl/maps/eUhKPwrpzF22" target="_blank" rel="noopener">
							<img class="img-responsive" src="@/assets/img/icon-local.png">
							<span class="span_localizacao">
								RUA GOMES JARDIM 201, TORRE B - SALA 708
							</span>
						</a>
					</p>
				</div>
				<div class="col-lg-2 col-md-1"></div>
			</b-row>
		</div>

		<section id="instalacoes" class="section-gallery desktop">
			<b-img :src="require('../assets/img/bg-localizacao.jpg')"></b-img>
		</section>

		<section id="instalacoes" class="section-gallery mobile">
			<div class='localizacao_bg'></div>
		</section>

		<footer id="contato" class="mb-5 pb-5">
			<h2 class="section-title text-center pt-5 vamos_iniciar">Vamos Iniciar?</h2>
			<b-row class="w-100">
				<div class="col-lg-4 col-md-2"></div>
				<div class="text-center col-lg-2 col-md-5 col-sm-12">
					<a href="https://wa.me/55051991216108" target="_blank">
						<img class="img-responsive" src="@/assets/img/whatsapp.png">
						<div class="pt-2">
							WhatsApp
						</div>
						<div>&nbsp;</div>
					</a>
				</div>
				
				<div class="text-center col-lg-2 col-md-5 col-sm-12">
					<a v-b-modal.modal_contato>
						<img class="img-responsive" src="@/assets/img/icon-email.png">
						<div class="pt-2">
							Mande um E-mail
						</div>
						<div class="pt-1">
							CONTATO@ICONS.MED.BR
						</div>
					</a>
				</div>
				<!--
				<div class="text-center col-lg-5 col-md-5 col-sm-12 criar_conta">
					<router-link to="/login" target="_blank">
						<img class="img-responsive" src="@/assets/img/icon-user.png">
						<div class="pt-2">
							Crie uma conta
						</div>
						<div>&nbsp;</div>
					</router-link>
				</div>
				-->
				<div class="col-lg-2 col-md-2"></div>
			</b-row>
			<b-row class="w-100 pt-5 mt-5">
				<div class="texto_rodape">
					CNPJ 29.749.609/0001-40 | Razão social: ICONS INTELIGENCIA EM CONSULTORIOS LTDA
				</div>
			</b-row>
		</footer>


		<b-modal size="lg" id="modal_contato" ref="modal_contato" hide-footer title="Contato">
			<b-row class="mt-1">
				<b-col>
					<b-form-group
						horizontal
						:label-cols="2"
						label="Nome"
						label-for="nome">
						<b-form-input
							maxlength="256"
							id="nome"
							v-model="contato_nome">
						</b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row class="mt-1">
				<b-col>
					<b-form-group
						horizontal
						:label-cols="2"
						label="E-mail"
						label-for="email">
						<b-form-input
							maxlength="256"
							id="email"
							v-model="contato_email">
						</b-form-input>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row class="mt-1">
				<b-col>
					<b-form-group
						horizontal
						:label-cols="2"
						label="Telefone"
						label-for="telefone">
						<the-mask
							class="form-control"
							v-model="contato_telefone"
							id="telefone"
							type="text"
							:mask="['(##) #####-####']">
						</the-mask>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row class="mt-1">
				<b-col>
					<b-form-group
						horizontal
						:label-cols="2"
						label="Mensagem"
						label-for="mensagem">
						<b-form-textarea
							id="mensagem"
							:rows="6"
							v-model="contato_mensagem">
						</b-form-textarea>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row class="justify-content-md-center">
				<b-col cols="12" md="auto">
					
				</b-col>
			</b-row>
			<b-row class="mt-5">
				<b-col class="text-center" :rows="6">
					<b-button type="submit" variant="primary" @click="enviarContato">Enviar</b-button>
				</b-col>
			</b-row>

		</b-modal>
	</div>
</template>

<script>
import 'vue-awesome/icons/bars'
import 'vue-awesome/icons/times'
import IconAwe from 'vue-awesome/components/Icon'


export default {
	name: 'Home',

	components: {
		'v-icon': IconAwe
	},

	data () {
		return {
			"carouselEl": 0,
			"menuopen": true,
			"contato_nome": '',
			"contato_email": '',
			"contato_telefone": '',
			"contato_mensagem": ''
		}
		
	},
	
	mounted(){
		let self = this;
		window.setInterval(()=>{
			self.slide();
		}, 4000);
	},

	methods: {
		slide: function(){
			if(this.carouselEl == 2){
				this.carouselEl = 0;
			}else{
				this.carouselEl++;
			}
		},

		openMenu: function(){
			this.menuopen = !this.menuopen;
			document.querySelector(".flutuante").classList.toggle('openMenu');
		},

		enviarContato: function(){

			if(this.contato_nome		== '' || this.contato_nome		== null ||
				this.contato_email		== '' || this.contato_email		== null ||
				this.contato_telefone	== '' || this.contato_telefone 	== null ||
				this.contato_mensagem	== '' || this.contato_mensagem 	== null){

				alert("Todos os campos são obrigatórios.");
				return;
			}

			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if(!re.test(String(this.contato_email).toLowerCase())){
				alert("E-mail não é válido.");
				return;
			}

			// abrir loader
			document.querySelector(".loading").style.display = 'block';

			let self = this;

			this.axios.post('usuario/contatoUsuario',
			{
				"contato_nome": this.contato_nome,
				"contato_email": this.contato_email,
				"contato_telefone": this.contato_telefone,
				"contato_mensagem": this.contato_mensagem
			}).then((response) => {

				// fechar loader
				document.querySelector(".loading").style.display = 'none';

				self.$refs.modal_contato.hide();

				self.contato_nome		= '';
				self.contato_email		= '';
				self.contato_telefone	= '';
				self.contato_mensagem	= '';

				self.$swal('', 'Sua solicitação foi recebida com sucesso!<br>Em breve entraremos em contato.');

				ga('gtag_UA_143498935_1.send', 'event', 'contato', '/usuario/contatoUsuario/');

			}).catch((error) => {

				// fechar loader
				document.querySelector(".loading").style.display = 'none';
				alert("Ocorreu um erro no contato. Envie e-mail diretamente para contato@icons.med.br .");
			});
		},

	}
}
</script>
