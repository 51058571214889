<template>

	<div class="home">
		<Home></Home>
	</div>
</template>

<script>
// @ is an alias to /src

import Home from '@/components/Home.vue'

export default {
	name: 'HomeView',
	components: {
		Home
	},

	mounted(){

		/*
		let aux		= document.querySelector(".home");
		let body	= document.querySelector("body");
		let html	= document.querySelector("html");
		let menu	= document.querySelector(".menumobile");
		let home_container	= document.querySelector("#home_container");
		let header	= document.querySelector(".header");

		aux.style.width = screen.width + "px";
		body.style.width = screen.width + "px";
		menu.style.width = screen.width + "px";
		home_container.style.width = screen.width + "px";
		header.style.width = screen.width + "px";

		html.style.maxWidth = screen.width + "px";
		body.style.maxWidth = screen.width + "px";

		window.addEventListener("resize", function(){
			let aux		= document.querySelector(".home");
			let body	= document.querySelector("body");
			let html	= document.querySelector("html");
			let menu	= document.querySelector(".menumobile");
			let home_container	= document.querySelector("#home_container");
			let header	= document.querySelector(".header");

			aux.style.width = screen.width + "px";
			body.style.width = screen.width + "px";
			menu.style.width = screen.width + "px";
			home_container.style.width = screen.width + "px";
			header.style.width = screen.width + "px";

			html.style.maxWidth = screen.width + "px";
			body.style.maxWidth = screen.width + "px";
		});
		*/
	}
}
</script>
